/**
 * Class representation of a Rfid Card
 * 
 * @author Floyd Kissoon
 * @since 201809287
 */
 export class RfidCardUse
 {
     id: number;
     student_id: number;
     rfid_operation_id: number;
     created_at: Date;
     updated_at: Date;
     
     student: any;
     rfid_operation: any;
 }
