/**
 * Class representation of a Teacher
 * 
 * @author Floyd Kissoon
 * @since 20180611
 */
export class Teacher {
    id: number;
    name: string;
    date_of_birth: Date;
    gender_id: number;
    email: string;
    sport_house_id: number;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    photo: string;
    subjects: string;

    /* Related */
    photo_url: string;
    gender: any;
    sport_house: any;
    constructor() {
        this.gender = {};
        this.sport_house = {};
    }
}
