/**
 * Class representation of a TimetableEntry
 * 
 * @author Floyd Kissoon
 * @since 20180611
 */
export class TimetableEntry
{
    id: number;
    start_time: string;
    end_time: string;
    subject_id: number;
    class_id: number;
    subclass_id: number;
    details: string;
    day_of_week: number;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    mlc_type_id: number;
    location_id: number;
}
