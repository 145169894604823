/**
 * Class representation of Exam Student
 * 
 * @author Joshua Kissoon
 * @since 20180711
 */
export class ExamStudent {
    id: number;
    exam_id: number;
    student_id: number;
    marks_obtained: number;
    grade_id: number;
    notes: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    registration_id: number;
    /* Related Attributes */
    student: any; 
    registration: any;
    
    loadFromMap(data: any)
    {
        this.id = data.id;
        this.exam_id = data.exam_id;
        this.student_id = data.student_id;
        this.marks_obtained = data.marks_obtained;
        this.grade_id = data.grade_id
        this.notes = data.notes 
        this.student = data.student 
        this.registration_id = data.registration_id;
    }
}
