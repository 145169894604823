/**
 * Class representation of a Student
 * 
 * @author Floyd Kissoon
 * @since 20180611
 */
export class Student
{
    id: number;
    first_name: string;
    last_name: string;
    other_names: string;
    date_of_birth: Date;
    gender_id: number;
    status_id: number;
    school_id: number;
    stream_id: number;
    sport_house_id: number;
    phone_number: number;
    email: string;
    address: string;
    father_id: number;
    mother_id: number;
    guardian_id: number;
    photo: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    
    /* Related Attributes */
    health_issue_ids: any;
    health_issues: any;
    sport_ids: any;
    sports: any;
    school: any;
    status: any;
    gender: any;
    stream: any;
    sport_house: any;
    father: any;
    mother: any;
    student_id: any;
    full_name:string;
    
    constructor()
    { 
        this.health_issue_ids = {};
        this.sport_ids = {};
        this.gender = {};
        this.status = {};
        this.school = {};
        this.stream = {};
        this.sport_house = {};
        this.father = {};
        this.mother = {};
    }
    
    loadData(data: any)
    {
        this.id = data.id;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.other_names = data.other_names;
        this.date_of_birth = data.date_of_birth;
        this.gender_id = data.gender_id;
        this.status_id = data.status_id;
        this.school_id = data.school_id;
        this.stream_id = data.stream_id != null ? data.stream_id : -1;
        this.sport_house_id = data.sport_house_id != null ? data.sport_house_id : -1;
        this.phone_number = data.phone_number;
        this.email = data.email;
        this.address = data.address;
        this.father_id = data.father_id != null ? data.father_id : -1;
        this.mother_id = data.mother_id != null ? data.mother_id : -1;
        this.guardian_id = data.guardian_id != null ? data.guardian_id : -1;
        this.photo = data.photo;
        this.user_id = data.user_id;
        
        this.health_issues = data.health_issues;
        this.sports = data.sports;
    }

}
