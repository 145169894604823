/**
 * Class representation of a Payment
 * 
 * @author Floyd Kissoon
 * @since 20180619
 */
export class Payment {
    id: number;
    student_id: number;
    registration_id: number;
    date: Date;
    total: number;
    details: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    is_approved: boolean;

    /* Related */
    payment_entries: any;
    student: any;
    registration:any;
    user: any;
    constructor()
    {
        
    }
    
    load(data: any)
    {
        this.id = data.id;
        this.student_id = data.student_id;
        this.registration_id = data.registration_id;
        this.date = data.date;
        this.total = data.total;
        this.details = data.details;
        this.user_id = data.user_id;
        this.is_approved = data.is_approved;
        
        this.payment_entries = data.payment_entries;
        this.student = data.student;
        this.registration = data.registration;
        this.user = data.user;
    }
}
