import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that updates Registration status
 * 
 * @author Floyd Kissoon
 * @since 20180806
 */
@Injectable()
export class RegistrationService {

    private promise;

    constructor(private dataManager: DataManager) { }

    getRegistrations(filters: any) {

        this.promise = this.dataManager.GET("api/registration", filters);

        return this.promise;
    }

    setClosed(registration) {

        this.promise = this.dataManager.PATCH("api/registration/" + registration.id + "/status-update/closed", registration);

        return this.promise;
    }

    getRegistrationPaymentStyles(filters: any) {
        var promise;
        promise = this.dataManager.GET("api/registration-payment-styles", filters);
        return promise;
    }
}