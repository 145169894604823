/**
 * Class representation of a Attendance Register
 * 
 * @author Floyd Kissoon
 * @since 20180623
 */
export class AttendanceRegister
{
    id: number;
    start_time: number;
    end_time: number;
    date: Date;
    subject_id: number;
    class_id: number
    subclass_id: number;
    notes: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    mlc_type_id: number;
    location_id: number;
    /* Related Attributes */
    entries: any;
    attendance_register_entries: any;
    subject: any;
    academic_class: any;
    subclass: any;
    mlc_type: any;
    
    constructor()
    { 
        this.entries = {};
        this.subject = {};
        this.academic_class = {};
        this.subclass = {};
        this.mlc_type = {};
    }
}
