/**
 * Class representation of a Subject
 * 
 * @author Floyd Kissoon
 * @since 20180611
 */
export class Subject
{
    id: number;
    title: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
}
