import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { ForumDataService } from "./forum-data.service";
import { Forum } from "./forum.model";

@Component({
    selector: "forum-selector",
    template: `<div class="type-selector-wrapper">
    <select name="type" [(ngModel)]="id" class="form-control" (change)="itemSelected()">
        <option value="0">-- Forum--</option>
        <option *ngFor="let forum of forums" value="{{forum.id}}">{{forum.title}}</option>
    </select>
</div>`
})
export class ForumSelectComponent implements OnInit {
    
    forums: Forum[];

    @Input() id: number;
    @Output() idChange: EventEmitter<number>;

    constructor(private dataService: ForumDataService) {
        this.forums = new Array<Forum>();
        this.idChange = new EventEmitter<number>();
    }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.dataService.getForums().then(
            res => {
                this.forums = res.items;
            }
        );
    }

    itemSelected() {
        this.idChange.emit(this.id);
    }
}