/**
 * Class representation of a Registration
 * 
 * @author Floyd Kissoon
 * @since 20180618
 */
export class Registration 
{
    id: number;
    student_id: number;
    academic_period_id: number;
    academic_class_id: number;
    monthly_fee: number
    registration_fee: number
    registration_fee_paid: number
    registration_date_from: Date;
    registration_date_to: Date;
    status_id: number
    user_id: number;
    created_at: Date;
    updated_at: Date
    registration_payment_style_id: number;
    mlc_type_id: number;
    location_id: number;
    
    /* Related Attributes */
    subject_ids: any;
    subjects: any;
    subclass_ids: any;
    subclasses: any;
    student: any;
    constructor() {
        this.subject_ids = {};
        this.subclass_ids = {};
        this.student = {};
    }
}

