import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that provides Facility
 * 
 * @author Floyd Kissoon
 * @since 20181002
 */
@Injectable()
export class FacilityDataService {

    private data: any;

    constructor(private dataManager: DataManager) {
    }

    getPublicFacilities(filters: any) {
        var promise;
        if (undefined !== this.data) {
            promise = new Promise((resolve, reject) => {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/public/facilities", filters);

        promise.then(
            res => {
                this.data = res;
            }
        );

        return promise;
    }
}