/**
 * Class representation of a slideshow item
 * 
 * @author Floyd Kissoon
 * @since 20180925
 */
export class SlideshowItem
{
    id: number;
    title: string;
    body: string;
    displayed: boolean;
    photo: string;
    photo_url: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
}