import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that provides Forum Data
 * 
 * @author Joshua Kissoon
 * @since 20180922
 */
@Injectable()
export class ForumDataService {
    private data: any;

    constructor(private dataManager: DataManager) { }

    getForums() {
        var promise;
        if (undefined !== this.data) {
            promise = new Promise((resolve, reject) => {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/forums", { "sort_by": "title|ASC" });

        promise.then(
            res => {
                this.data = res;
            }
        );
        return promise;
    }
}