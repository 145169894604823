/**
 * Class representation of a Award
 * 
 * @author Floyd Kissoon
 * @since 20181001
 */
export class Award 
{
    id: number;
    title: string;
    photo: string;
    photo_url: string;
    details: string;
    created_at: Date;
    updated_at: Date;
    user_id: number;
}