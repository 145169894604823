/**
 * Class representation of a Attendance Register Entry
 * 
 * @author Floyd Kissoon
 * @since 20180630
 */
 export class AttendanceRegisterEntry
 {
     id: number;
     attendance_register_id: number;
     student_id: number;
     is_present: boolean;
     
     student: any;
     
    loadFromMap(data: any)
    {
        this.id = data.id;
        this.attendance_register_id = data.attendance_register_id;
        this.student_id = data.student_id;
        this.is_present = data.is_present;
        this.student = data.student
    }
 }
