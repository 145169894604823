import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

import { User } from "techlify-ng";

/**
 * Service that manages information about the current user
 * 
 * @author Joshua Kissoon
 * @since 20170817
 */
@Injectable()
export class CurrentUserService {

    private user: User;
    private data: any;

    constructor(private dataManager: DataManager) {
        this.user = new User();
    }

    getUser(): any {
        return this.user;
    }

    getCurrentUser(): any {
        var promise;
        if (undefined !== this.data) {
            promise = new Promise((resolve, reject) => {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/user/current");

        promise.then(
            res => {
                if (null != res.user.id) {
                    this.data = res;
                    this.user.loadFromMap(res.user);
                }
            }
        );

        return promise;
    }

}
