/**
 * Class representation of a user
 * 
 * @author Joshua Kissoon
 * @since 20170709
 */
export class User
{
    id: number;
    name: string;
    email: string;
    password: string;

    roles: any;
    permissions: any;
    
    constructor()
    {
        this.id = 0;
    }

    loadFromMap(data: any)
    {
        this.id = data.id;
        this.email = data.email;
        this.name = data.name;

        this.roles = (data.roles == null) ? [] : data.roles;
        this.permissions = (data.permissions == null) ? [] : data.permissions;
    }

    hasRole(id: number)
    {
        if(this.roles == null)
        {
            return false;
        }

        for(var role of this.roles)
        {
            if(role.id == id)
            {
                return true;
            }
        }

        return false;
    }

    hasPermission(slug: string)
    {
        if(this.permissions == null)
        {
            return false;
        }

        for(var permission of this.permissions)
        {
            if(permission.slug == slug)
            {
                return true;
            }
        }

        return false;
    }


}
