import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule
        
    ],
    declarations: [
    ],
    providers: [],
    exports: []
})
export class PaymentCoreModule { }
