/**
 * Class representation of a CXC Exam Student Grade
 * 
 * @author Floyd Kissoon
 * @since 20180821
 */
export class CxcExamStudentGrade {
    id: number;
    student_id: number;
    subject_id: number;
    grade_id: number;
    type_id: number;
    proficiency_id: number;
    exam_id: number;
    remarks: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

    /* Related */
    exam: any;
    student: any;
    subject: any;
    grade: any;
    type: any;
    proficiency: any;
}