/**
 * Class representation of a Charge 
 * 
 * @author Floyd Kissoon
 * @since 20190619
 */
export class Charge
{
    id: number;
    registration_id: number;
    student_id: number;
    date: Date;
    amount: number;
    particulars: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    
}


