/**
 * Class representation of Forum
 * 
 * @author Joshua Kissoon
 * @since 20180922
 */
export class ForumTag {
    id: number;
    title: string;
    description: string;
    forum_id: number;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
}
