/**
 * Class representation of a Testimonial
 * 
 * @author Krishna Hardat
 * @since 20180614
 */
 
 export class Testimonial
 {
     id: number;
     title: string;
     testimonial_by: string;
     parent_of: string;
     testimonial: string;
     user_id: number;
     created_at: Date;
     updated_at: Date;
     deleted_at: Date;
     
 }
