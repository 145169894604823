/**
 * Class representation of a News Article.
 * 
 * @author Krishna Hardat
 * @since 20180616
 */
 
 export class NewsArticle
 {
     id: number;
     headline: string;
     content: string;
     user_id: number;
     created_at: Date;
     updated_at: Date;
     deleted_at: Date;
     date: Date;
 }
