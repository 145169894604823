/**
 * Class representation of Facility
 * 
 * @author Floyd Kissoon
 * @since 20180704
 */
 export class Facility
 {
     id: number;
     name: string;
     description: string;
     photo: string;
     user_id: number;
     created_at: Date;
     updated_at: Date;
     deleted_at: Date;
     
     /* Related Attributes */
     photo_url : string;
 }
