/**
 * Class representation of a CXC Exam 
 * 
 * @author Floyd Kissoon
 * @since 20180821
 */
export class CxcExam
{
    id: number;
    title: string;
    year: number;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
}