import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { ForumSelectComponent } from "./forum-selector.component"
import { ForumDataService } from "./forum-data.service"


@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule
    ],

    // module dependencies
    declarations: [
        ForumSelectComponent, 
    ],
    providers: [ForumDataService],
    exports: [ForumSelectComponent]
})
export class ForumCoreModule { }
