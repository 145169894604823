import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that provides News Article Data
 * 
 * @author Floyd Kissoon
 * @since 20180827
 */
@Injectable()
export class NewsArticleDataService {

    private data: any;
    
    constructor(private dataManager: DataManager) {
    }

    getNewsArticles(filters: any) {
        var promise;
        if (undefined !== this.data) {
            promise = new Promise((resolve, reject) => {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/public/news-articles", filters);

        promise.then(
            res => {
                this.data = res;
            }
        );

        return promise;
    }
}