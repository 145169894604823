import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that provides Teacher Data
 * 
 * @author Floyd Kissoon
 * @since 20180611
 */
@Injectable()
export class TeacherDataService {

    private data: any;

    constructor(private dataManager: DataManager) {

    }

    getTeachers(filters: any) {
        var promise;
        if (undefined !== this.data) {
            promise = new Promise((resolve, reject) => {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/public/teachers", filters);

        promise.then(
            res => {
                this.data = res;
            }
        );

        return promise;
    }
}