/**
 * Class representation of Exam
 * 
 * @author Joshua Kissoon
 * @since 20180711
 */
export class Exam {
    id: number;
    date: Date;
    description: string;
    exam_category_id: number;
    teacher_id: number;
    subject_id: number;
    class_id: number;
    subclass_id: number;
    maximum_marks: number;
    details: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    academic_period_id: number;
    location_id: number;
    /* Related Attributes */
    students: any;
    exam_students: any;
    academic_period;
}
