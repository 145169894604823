import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that provides Cxc Exam Data
 * 
 * @author Floyd Kissoon
 * @since 20180828
 */
@Injectable()
export class CxcExamDataService {
    private data: any;
    private studentReport: any;
    
    constructor(private dataManager: DataManager) { }

    getCxcExams() {
        var promise;
        if (undefined !== this.data) {
            promise = new Promise((resolve, reject) => {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/cxc-exams", {});

        promise.then(
            res => {
                this.data = res;
            }
        );
        return promise;
    }
    
    getStudentCxcPerformance(filters: any)
    {
        var promise;
        if (undefined !== this.studentReport) {
            promise = new Promise((resolve, reject) => {
                resolve(this.studentReport);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/cxc-exam-student-performance-report", filters);

        promise.then(
            res => {
                this.studentReport = res;
            }
        );
        return promise;        
    }
}