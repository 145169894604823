import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that provides Attendance Register Data
 * 
 * @author Floyd Kissoon
 * @since 20180623
 */
@Injectable()
export class AttendanceRegisterDataService
{

    private data: any;

    constructor(private dataManager: DataManager)
    {

    }

    getAttendanceRegisters()
    {
        var promise;
        if(undefined !== this.data)
        {
            promise = new Promise((resolve, reject) =>
            {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/attendance-register", { sort_by: "id|ASC" });

        promise.then(
            res =>
            {
                this.data = res;
            }
        );

        return promise;
    }
}