import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that provides Slideshow item Data
 * 
 * @author Floyd Kissoon
 * @since 20180926
 */
@Injectable()
export class SlideshowItemDataService {

    private data: any;

    constructor(private dataManager: DataManager) {

    }

    getSlideshowItem() {
        var promise;
        if (undefined !== this.data) {
            promise = new Promise((resolve, reject) => {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/public/slideshow-items", {});

        promise.then(
            res => {
                this.data = res;
            }
        );

        return promise;
    }
}